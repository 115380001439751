import React, { useState } from 'react';
import {
  TextField,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Tabs,
  Tab,
  Button,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

function MeasurementForm({ addMeasurement }) {
  const [measurementData, setMeasurementData] = useState({
    measurementType: 'E2E',
    measurementSetId: '',
    eye: '',
    iopValue: '',
    comments: '',
  });

  const [errors, setErrors] = useState({});

  const handleMeasurementChange = (e) => {
    const { name, value } = e.target;

    // Parse numerical values
    const parsedValue =
      name === 'iopValue' || name === 'measurementSetId' ? value.replace(/\D/g, '') : value;

    setMeasurementData((prevData) => ({ ...prevData, [name]: parsedValue }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleTabChange = (event, newValue) => {
    setMeasurementData((prevData) => ({
      ...prevData,
      measurementType: newValue,
      // Clear fields that are not relevant to the new measurement type
      measurementSetId: newValue === 'E2E' ? prevData.measurementSetId : '',
      iopValue: newValue !== 'E2E' ? prevData.iopValue : '',
    }));
    setErrors({});
  };

  const validateFields = () => {
    const newErrors = {};
    if (measurementData.measurementType !== 'E2E' && !measurementData.iopValue) {
      newErrors.iopValue = 'IOP Value is required.';
    }
    if (measurementData.measurementType === 'E2E' && !measurementData.measurementSetId) {
      newErrors.measurementSetId = 'Measurement Set ID is required for E2E.';
    }
    if (!measurementData.eye) {
      newErrors.eye = 'Please select an eye (OD or OS).';
    }
    return newErrors;
  };

  const handleAddMeasurement = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const newMeasurement = {
      ...measurementData,
      measurementSetId: measurementData.measurementSetId
        ? parseInt(measurementData.measurementSetId, 10)
        : undefined,
    };

    if (measurementData.measurementType !== 'E2E') {
      newMeasurement.iopValue = parseFloat(measurementData.iopValue);
    } else {
      delete newMeasurement.iopValue; // Remove iopValue for E2E
    }

    addMeasurement(newMeasurement);

    // Reset relevant fields after adding
    setMeasurementData((prev) => ({
      ...prev,
      measurementSetId: '',
      eye: '',
      iopValue: '',
      comments: '',
    }));

    setErrors({});
  };

  // Helper function for dynamic styles based on eye selection
  const getEyeColor = (lightShade) => {
    if (measurementData.eye === 'OD') {
      return lightShade ? '#e3f2fd' : '#bbdefb';
    } else if (measurementData.eye === 'OS') {
      return lightShade ? '#fce4ec' : '#f8bbd0';
    }
    return undefined;
  };

  return (
    <Box mb={3}>
      <Box
        sx={{
          backgroundColor: '#FDFCFC',
          px: 4,
          pb: 4,
          borderRadius: 5,
        }}
      >
        <Tabs
          value={measurementData.measurementType}
          onChange={handleTabChange}
          sx={{
            marginBottom: 2,
            '.MuiTab-root': {
              backgroundColor: getEyeColor(true),
              color: '#000',
              '&:hover': {
                backgroundColor: getEyeColor(false),
              },
            },
            '.Mui-selected': {
              backgroundColor: getEyeColor(false),
              color: '#000',
            },
          }}
        >
          <Tab label="E2E" value="E2E" />
          <Tab label="Goldman" value="Goldman" />
          <Tab label="ICare" value="ICare" />
        </Tabs>

        <Grid size={3}>
          <FormControl component="fieldset" error={Boolean(errors.eye)}>
            <FormLabel component="legend">Eye Laterality</FormLabel>
            <RadioGroup
              row
              name="eye"
              value={measurementData.eye}
              onChange={handleMeasurementChange}
            >
              <FormControlLabel value="OD" control={<Radio />} label="OD" />
              <FormControlLabel value="OS" control={<Radio />} label="OS" />
            </RadioGroup>
            {errors.eye && <FormHelperText>{errors.eye}</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid container spacing={3}>
          {/* Conditionally render IOP Value field */}
          {measurementData.measurementType !== 'E2E' && (
            <Grid size={2}>
              <TextField
                label="IOP Value"
                name="iopValue"
                type="number"
                value={measurementData.iopValue}
                onChange={handleMeasurementChange}
                fullWidth
                error={Boolean(errors.iopValue)}
                helperText={errors.iopValue}
              />
            </Grid>
          )}

          {/* Conditionally render Measurement Set ID field */}
          {measurementData.measurementType === 'E2E' && (
            <Grid size={4}>
              <TextField
                label="Measurement Set ID"
                name="measurementSetId"
                type="number"
                value={measurementData.measurementSetId}
                onChange={handleMeasurementChange}
                fullWidth
                error={Boolean(errors.measurementSetId)}
                helperText={errors.measurementSetId}
              />
            </Grid>
          )}

          <Grid size={6}>
            <TextField
              label="Comments"
              name="comments"
              value={measurementData.comments}
              onChange={handleMeasurementChange}
              fullWidth
            />
          </Grid>
        </Grid>

        <Box mt={2}>
          <Button
            variant="contained"
            onClick={handleAddMeasurement}
            sx={{
              backgroundColor: getEyeColor(true),
              color: '#000',
              '&:hover': {
                backgroundColor: getEyeColor(false),
              },
            }}
          >
            {`Add Measurement ${measurementData.eye ? `(${measurementData.eye})` : ''}`}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default MeasurementForm;
