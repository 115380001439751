// ReAuthModal.js
import React, { useState, useContext } from 'react';
import { Modal, Box, TextField, Button, Typography, Alert } from '@mui/material';
import { AuthContext } from './AuthContext';
import useApi from './api';

const ReAuthModal = ({ open, onClose }) => {
  const { login, setReauthRequired } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setError] = useState('');
  const api = useApi();

  const handleReAuth = async () => {
    try {
      const requestData = {
        username,
        password,
      };

      const response = await api.post('/login', requestData);
      const { access_token, role } = response.data;
      if (access_token && role) {
        login(access_token, username, role);
        // Automatically close the modal after successful login
        onClose();
      } else {
        setError('Invalid response from server.');
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          setError('Invalid username or password');
        } else {
          setError(`Server responded with status ${err.response.status}`);
        }
      } else if (err.request) {
        setError('No response from server. Please try again later.');
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          borderRadius: 2,
          p: 4,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Session Expired
        </Typography>
        {errorMsg && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {errorMsg}
          </Alert>
        )}
        <TextField
          label="Username"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" fullWidth sx={{ mt: 2 }} onClick={handleReAuth}>
          Login
        </Button>
      </Box>
    </Modal>
  );
};

export default ReAuthModal;
