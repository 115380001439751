// frontend/src/App.js
import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './Components/LoginPage';
import ClinicianForm from './Components/ClinicianForm';
import Reporting from './Components/Reporting';
import ProtectedRoute from './ProtectedRoute';
import { AuthContext } from './Components/AuthContext';
import ReAuthModal from './Components/ReAuthModal';

function App() {
  const { reauthRequired, setReauthRequired } = useContext(AuthContext);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/ClinicianForm"
          element={
            <ProtectedRoute requiredRole="clinician">
              <ClinicianForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reporting"
          element={
            <ProtectedRoute requiredRole="reporter">
              <Reporting />
            </ProtectedRoute>
          }
        />
        {/* Redirect any unknown routes to login */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
      <ReAuthModal
        open={reauthRequired}
        onClose={() => setReauthRequired(false)}
      />
    </Router>
  );
}

export default App;
