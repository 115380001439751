// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext({
  authToken: null,
  username: null,
  role: null,
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
  reauthRequired: false,
  setReauthRequired: () => {},
});

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(() => localStorage.getItem('token'));
  const [username, setUsername] = useState(() => localStorage.getItem('username'));
  const [role, setRole] = useState(() => localStorage.getItem('role'));
  const [isAuthenticated, setIsAuthenticated] = useState(!!authToken);
  const [reauthRequired, setReauthRequired] = useState(false);

  const login = (token, username, role) => {
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
    localStorage.setItem('role', role);
    setAuthToken(token);
    setUsername(username);
    setRole(role);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    setAuthToken(null);
    setUsername(null);
    setRole(null);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const savedUsername = localStorage.getItem('username');
    const savedRole = localStorage.getItem('role');
    if (token && savedUsername && savedRole) {
      setAuthToken(token);
      setUsername(savedUsername);
      setRole(savedRole);
      setIsAuthenticated(true);
    } else {
      setAuthToken(null);
      setUsername(null);
      setRole(null);
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authToken,
        username,
        role,
        isAuthenticated,
        login,
        logout,
        reauthRequired,
        setReauthRequired,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
