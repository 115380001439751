// api.js
import axios from 'axios';
import { useContext, useMemo } from 'react';
import { AuthContext } from './AuthContext';

const useApi = () => {
  const { authToken, setReauthRequired } = useContext(AuthContext);

  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: '/api',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    instance.interceptors.request.use(
      (config) => {
        if (authToken) {
          config.headers['Authorization'] = `Bearer ${authToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          if (window.location.pathname !== '/login') {
            setReauthRequired(true);
          }
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [authToken, setReauthRequired]);

  return api;
};

export default useApi;
