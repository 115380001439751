import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Alert,
  Button,
  Typography,
  Paper,
  Divider,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PatientInfo from './PatientInfo.js';
import MeasurementForm from './MeasurementForm';
import MeasurementList from './MeasurementList';
import useApi from './api';
import { AuthContext } from './AuthContext';



function ClinicianForm() {
  const theme = useTheme();
  
  const [patientData, setPatientData] = useState({
    patientId: '',
    dob: '',
    ethnicity: '',
    sex: '',
    pastOcularHistory: '',
    pachymetryOD: null,
    pachymetryOS: null,
    autorefractionOD: '',
    autorefractionOS: '',
    cornealAstigmatismOD: null,
    cornealAstigmatismOS: null,
  });
  
  const numericFields = [
    'pachymetryOD',
    'pachymetryOS',
    'cornealAstigmatismOD',
    'cornealAstigmatismOS',
  ];

  const [measurements, setMeasurements] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const api = useApi();
  const { logout, username } = useContext(AuthContext);

  const handlePatientChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
  
    // If the field is numeric, convert it.
    if (numericFields.includes(name)) {
      // If the input is empty, we can set it to null,
      // otherwise convert it to a number.
      newValue = value === '' ? null : Number(value);
    }
  
    // Update the state with the new value.
    setPatientData((prevData) => ({ ...prevData, [name]: newValue }));
    setIsDirty(true);
  };

  const addMeasurement = (measurement) => {
    setMeasurements((prev) => [...prev, measurement]);
    setIsDirty(true);
  };

  const deleteMeasurement = (indexToDelete) => {
    setMeasurements((prevMeasurements) =>
      prevMeasurements.filter((_, index) => index !== indexToDelete),
    );
    setIsDirty(true);
  };

  const clearAllFields = () => {
    setPatientData({
      patientId: '',
      dob: '',
      ethnicity: '',
      sex: '',
      pastOcularHistory: '',
      pachymetryOD: null,
      pachymetryOS: null,
      autorefractionOD: '',
      autorefractionOS: '',
      cornealAstigmatismOD: null,
      cornealAstigmatismOS: null,
    });
    setMeasurements([]);
    setIsDirty(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Copy the current patientData.
    let processedPatientData = { ...patientData };

    // Convert each numeric field
    numericFields.forEach((field) => {
      const value = processedPatientData[field];
      processedPatientData[field] = value === '' ? null : Number(value);
    });

    try {
      // 1) Check if user has OD/OS measurements in measurements[]
      const hasODMeasurement = measurements.some((m) => m.eye === 'OD');
      const hasOSMeasurement = measurements.some((m) => m.eye === 'OS');

      // 2) If OD is present, enforce required OD fields
      if (hasODMeasurement) {
        // Must NOT be null or NaN
        if (patientData.pachymetryOD === null || isNaN(patientData.pachymetryOD)) {
          setErrorMessage('If OD measurements are supplied, pachymetry is required and must be numeric.');
          return;
        }
        if (patientData.cornealAstigmatismOD === null || isNaN(patientData.cornealAstigmatismOD)) {
          setErrorMessage('If OD measurements are supplied, corneal astigmatism is required and must be numeric.');
          return;
        }
      }

      // 3) If OS is present, enforce required OS fields
      if (hasOSMeasurement) {
        // Must NOT be null or NaN
        if (patientData.pachymetryOS === null || isNaN(patientData.pachymetryOS)) {
          setErrorMessage('If OS measurements are supplied, pachymetry is required and must be numeric.');
          return;
        }
        if (patientData.cornealAstigmatismOS === null || isNaN(patientData.cornealAstigmatismOS)) {
          setErrorMessage('If OS measurements are supplied, corneal astigmatism is required and must be numeric.');
          return;
        }
      }
      
      // 4) If validation passes, build the data and submit
      const dataToSubmit = {
        ...patientData,
        username,
        measurements,
      };
  
      const response = await api.post('/upload-data-clinic', dataToSubmit, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        setSuccessMessage('Data submitted successfully!');
        clearAllFields();
        setErrorMessage('');
      } else {
        throw new Error(response.data.error || 'Submission failed');
      }
    } catch (error) {
      console.error('Error:', error);

      if (error.response && error.response.status === 401) {
        let errorMessageToDisplay = 'Session expired. Please log in again.';
        setErrorMessage(errorMessageToDisplay);
        setSuccessMessage('');
      }
      else {
        const serverError =
          error.response?.data?.error || error.message || 'Submission failed';
        const serverDetails = error.response?.data?.details;
    
        let errorMessageToDisplay = serverError;
        if (serverDetails) {
          errorMessageToDisplay += `: ${serverDetails}`;
        }
        setErrorMessage(errorMessageToDisplay);
        setSuccessMessage('');
      }
    }
  };
  
    

  // Handle unsaved changes warning
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  // Handle Logout
  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
      {/* Left side: The original form content in a Paper */}
      <Paper
        component="form"
        onSubmit={handleSubmit}
        elevation={3}
        sx={{
          p: 4,
          flex: 1,
          borderRadius: 2,
          backgroundColor: '#F5F5F5',
        }}
      >
        {/* Logout Button */}
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            variant="outlined"
            style={{
              color: '#ffffff',
              borderColor: '#4caf50',
              backgroundColor: '#4caf50',
            }}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>

        {/* Patient Information Section */}
        <PatientInfo data={patientData} handleChange={handlePatientChange} />

        {/* Measurement Form Section */}
        <MeasurementForm addMeasurement={addMeasurement} />

        {/* Success or Error Messages */}
        {successMessage && (
          <Alert
            severity="success"
            onClose={() => setSuccessMessage('')}
            sx={{ mb: 3 }}
          >
            {successMessage}
          </Alert>
        )}

        {errorMessage && (
          <Alert
            severity="error"
            onClose={() => setErrorMessage('')}
            sx={{ mb: 3 }}
          >
            {errorMessage}
          </Alert>
        )}

        {/* Measurement List Section */}
        <MeasurementList
          measurements={measurements}
          deleteMeasurement={deleteMeasurement}
        />
      </Paper>

      {/* Right side: Combined “Not Eligible If” criteria in its own Paper */}
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          maxWidth: '350px',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            mb: 2,
            fontWeight: 'bold',
            color: theme.palette.primary.main,
          }}
        >
          Not Eligible If:
        </Typography>

        <Divider sx={{ mb: 2 }} />

        <Box
          component="ul"
          sx={{
            pl: 4,
            mb: 0,
            '& li': { mb: 1, lineHeight: 1.5 },
          }}
        >
          {/* The inverted inclusion criterion: “Under 18 years old” */}
          <li>Under 18 years old</li>

          {/* Previously “Exclusion Criteria” items */}
          <li>Subjects with only one functional eye</li>
          <li>Subjects having poor or eccentric fixation in the study eye(s)</li>
          <li>High corneal astigmatism &gt;3D in the study eye(s)</li>
          <li>Central corneal scarring</li>
          <li>
            History of prior incisional glaucoma surgery or corneal surgery,
            including corneal refractive laser surgery in the study eye(s)
          </li>
          <li>Microphthalmos</li>
          <li>Buphthalmos</li>
          <li>
            Contact lens use within one week of continuous wear and within one
            hour if lens is worn occasionally
          </li>
          <li>Dry eyes (clinically significant)</li>
          <li>Lid squeezers - blepharospasm</li>
          <li>Nystagmus</li>
          <li>Keratoconus</li>
          <li>
            Any other corneal or conjunctival pathology or infection relevant to
            this study
          </li>
          <li>
            Central corneal thickness &gt;600 μm or &lt;500 μm in the study
            eye(s)
          </li>
          <li>
            Cataract Extraction within last 2 months in the study eye(s)
          </li>
        </Box>
      </Paper>
    </Box>
  );
}

export default ClinicianForm;
