import React, { useMemo } from 'react';
import { Paper, Typography } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import {
  Chart as ChartJS,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  annotationPlugin,
  Tooltip,
  Legend
);

const HistogramBarChart = ({ data, title, xAxisLabel, yAxisLabel, thresholds }) => {
  const chartData = useMemo(() => ({
    labels: data.map((point) => point.label), // Assumes data has a label property for x-axis labels
    datasets: [
      {
        label: title,
        data: data.map((point) => point.value),
        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Set bar color
        borderColor: 'rgba(75, 192, 192, 1)', // Set bar border color
        borderWidth: 1,
      },
    ],
  }), [data, title]);

  const options = useMemo(() => ({
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: true, // Enable tooltips for bars
        callbacks: {
          label: function (context) {
            // Optionally format tooltip labels to show integers
            return ` ${Math.round(context.parsed.y)}`;
          },
        },
      },
      annotation: {
        annotations: thresholds?.map((threshold, index) => ({
          type: 'line',
          yMin: threshold.value,
          yMax: threshold.value,
          borderColor: threshold.color || 'red',
          borderDash: threshold.dash || [6, 6],
          borderWidth: threshold.width || 2,
          label: {
            content: threshold.label || '',
            enabled: true,
            position: 'end',
          },
        })) || [],
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: xAxisLabel || '',
        },
      },
      y: {
        title: {
          display: true,
          text: yAxisLabel || '',
        },
        ticks: {
          // Ensure y-axis ticks are integers
          callback: function (value) {
            if (Number.isInteger(value)) {
              return value;
            }
          },
          stepSize: 1, // Set step size to 1 for integer increments
          beginAtZero: true,
        },
      },
    },
  }), [thresholds, xAxisLabel, yAxisLabel]);

  if (!data || !Array.isArray(data) || data.length === 0) {
    return (
      <Paper sx={{ marginBottom: 1, padding: 1 }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2">No data available for this chart.</Typography>
      </Paper>
    );
  }

  return (
    <Paper sx={{ marginBottom: 1, padding: 1 }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Bar data={chartData} options={options} />
    </Paper>
  );
};

export default HistogramBarChart;
