import React from 'react';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
} from '@mui/material';

const DataTable = ({ data, title, columns }) => {
  // Determine the order of columns
  const columnOrder = columns || (data.length > 0 ? Object.keys(data[0]) : []);

  return (
    <Paper sx={{ marginBottom: 1 }}>
      <Typography variant="h6" gutterBottom sx={{ padding: 1 }}>
        {title}
      </Typography>

      {/* Wrap Table in a TableContainer to enable horizontal scrolling */}
      <TableContainer sx={{ overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {columnOrder.map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    backgroundColor: 'grey.200',
                    ...(header === 'Comments' && {
                      minWidth: 350,
                      whiteSpace: 'normal', 
                      wordWrap: 'break-word', 
                    }),
                  }}
                >
                  {header.charAt(0).toUpperCase() + header.slice(1)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columnOrder.map((column, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    sx={
                      column === 'Comments'
                        ? {
                            minWidth: 350,
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                          }
                        : {}
                    }
                  >
                    {row[column]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DataTable;
