import React, { useEffect, useState, useContext } from 'react';
import {
  Container,
  Typography,
  Alert,
  CircularProgress,
  Grid2,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
} from '@mui/material';
import useApi from './api';
import BlandAltmanPlot from './BlandAltmanPlot';
import HistogramBarChart from './Histogram';
import DataTable from './Table';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';

function Reporting() {
  const [measurementData, setMeasurementData] = useState([]);
  const [ansiDeployedData, setAnsiDeployedData] = useState([]);
  const [ansiProposedData, setAnsiProposedData] = useState([]);
  const [blandAltmanDeployed, setBlandAltmanDeployed] = useState([]);
  const [blandAltmanProposed, setBlandAltmanProposed] = useState([]);
  const [population, setPopulation] = useState([]);
  const [errors, setErrors] = useState({ tooNear: [], tooFar: [], outlierRebounds: [] });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [firmwareVersion, setFirmwareVersion] = useState('all');

  const { logout, username } = useContext(AuthContext);
  const navigate = useNavigate();
  const api = useApi();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fwParam = `firmware_version=${firmwareVersion}`;

        const [
          measurementResponse,
          ansiDeployedResponse,
          ansiProposedResponse,
          blandAltmanDeployedResponse,
          blandAltmanProposedResponse,
          populationResponse,
          errorsResponse,
        ] = await Promise.all([
          api.get(`/reporting-clinic-table?${fwParam}`),
          api.get(`/ansi-table?model=deployed&${fwParam}`),
          api.get(`/ansi-table?model=proposed&${fwParam}`),
          api.get(`/bland-altman?model=deployed&${fwParam}`),
          api.get(`/bland-altman?model=proposed&${fwParam}`),
          api.get(`/population-data?${fwParam}`),
          api.get(`/errors-data?${fwParam}`),
        ]);

        setMeasurementData(measurementResponse.data);
        setAnsiDeployedData(ansiDeployedResponse.data);
        setAnsiProposedData(ansiProposedResponse.data);
        setBlandAltmanDeployed(blandAltmanDeployedResponse.data);
        setBlandAltmanProposed(blandAltmanProposedResponse.data);
        setPopulation(populationResponse.data);
        setErrors(errorsResponse.data);
      } catch (err) {
        setError('Failed to fetch data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [api, firmwareVersion]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <Container>
      <Typography variant="h2" gutterBottom>
        Eye to Eye Data Collection Progress Report
      </Typography>

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <FormControl variant="outlined" sx={{ minWidth: 160 }}>
          <InputLabel>Firmware</InputLabel>
          <Select
            label="Firmware Version"
            value={firmwareVersion}
            onChange={(e) => setFirmwareVersion(e.target.value)}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="v1.4.1">1.4.1</MenuItem>
            <MenuItem value="v1.5.0">1.5.0</MenuItem>
            // Future versions go here.
          </Select>
        </FormControl>

        <Button
          variant="outlined"
          style={{ color: '#ffffff', borderColor: '#4caf50', backgroundColor: '#4caf50' }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <>
          <Typography variant="h4" component="h1" gutterBottom>
            Performance
          </Typography>

          <Grid2 container spacing={2} sx={{ mb: 4 }}>
            <Grid2 item size={6}>
              <DataTable
                data={ansiDeployedData}
                title="Deployed Model"
                columns={['IOP', 'eyes', 'mean abs error', 'outliers', 'max error']}
              />
            </Grid2>
            <Grid2 item size={6}>
              <DataTable
                data={ansiProposedData}
                title="Proposed Model (R&D)"
                columns={['IOP', 'eyes', 'mean abs error', 'outliers', 'max error']}
              />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2} sx={{ mb: 4 }}>
            <Grid2 item size={6}>
              <BlandAltmanPlot data={blandAltmanDeployed} title="Deployed Model" />
            </Grid2>
            <Grid2 item size={6}>
              <BlandAltmanPlot data={blandAltmanProposed} title="Proposed Model (R&D)" />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2} sx={{ mb: 8 }}>
            <Grid2 item size={4}>
              <HistogramBarChart
                data={errors.tooNear}
                title="Too Near Errors Per Set"
                xAxisLabel="Too Near Errors Per Set"
                yAxisLabel="Count Measurement Sets"
              />
            </Grid2>
            <Grid2 item size={4}>
              <HistogramBarChart
                data={errors.tooFar}
                title="Too Far Errors Per Set"
                xAxisLabel="Too Far Errors Per Set"
                yAxisLabel="Count Measurement Sets"
              />
            </Grid2>
            <Grid2 item size={4}>
              <HistogramBarChart
                data={errors.outlierRebounds}
                title="Outlier Rebounds Per Set"
                xAxisLabel="Outlier Rebounds Per Set"
                yAxisLabel="Count Measurement Sets"
              />
            </Grid2>
          </Grid2>

          <Typography variant="h4" component="h1" gutterBottom>
            Patient Population Physiology Distribution
          </Typography>

          <Grid2 container spacing={2} sx={{ mb: 4 }}>
            <Grid2 item size={4}>
              <HistogramBarChart
                data={population.pachy}
                title="Pachymetry"
                xAxisLabel="Micrometer Range"
                yAxisLabel="Count (Eyes)"
              />
            </Grid2>
            <Grid2 item size={4}>
              <HistogramBarChart
                data={population.astigmatism}
                title="Astigmatism"
                xAxisLabel="Diopters Range"
                yAxisLabel="Count (Eyes)"
              />
            </Grid2>
            <Grid2 item size={4}>
              <HistogramBarChart
                data={population.user}
                title="Clinician ID"
                xAxisLabel="User"
                yAxisLabel="Count (Eyes)"
                />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2} sx={{ mb: 8 }}>
            <Grid2 item size={4}>
              <HistogramBarChart
                data={population.age}
                title="Age"
                xAxisLabel="Age Range"
                yAxisLabel="Count (Eyes)"
              />
            </Grid2>
            <Grid2 item size={4}>
              <HistogramBarChart
                data={population.race}
                title="Race"
                xAxisLabel="Race"
                yAxisLabel="Count (Eyes)"
              />
            </Grid2>
            <Grid2 item size={4}>
              <HistogramBarChart
                data={population.sex}
                title="Sex"
                xAxisLabel="Sex"
                yAxisLabel="Count (Eyes)"
              />
            </Grid2>
          </Grid2>

          <DataTable
            data={measurementData}
            title="All Records"
            columns={[
              'Patient ID',
              'Clinician ID',
              'E2E IOP',
              'Ref IOP (GAT)',
              'Predicate IOP (ICare)',
              'E2E Set ID',
              'Firmware Version',
              'Meas Count Ref',
              'Meas Count Predicate',
              'Too Near',
              'Too Far',
              'Anomaly',
              'Pachy',
              'Astigmatism',
              'Comments',
            ]}
          />
        </>
      )}
    </Container>
  );
}

export default Reporting;
