import React from 'react';
import {
  TextField,
  Typography,
  Box,
  MenuItem,
  FormControl,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

function PatientInfo({ data, handleChange }) {
  // Define the options for Ethnicity and Sex
  const ethnicityOptions = [
    'White',
    'Hispanic or Latino',
    'Black or African American',
    'Asian',
    'Native American or Alaska Native',
    'Other',
    'Prefer not to say',
  ];

  const sexOptions = [
    'Male',
    'Female',
    'Other',
    'Prefer not to say',
  ];

  // Helper function to display numeric values.
  // Returns an empty string if the value is null.
  const displayValue = (value) => (value === null ? '' : value);

  return (
    <Box mb={2} mt={3}>
      <Box
        sx={{
          backgroundColor: '#FDFCFC', // Light gray
          px: 4,
          py: 2,
          borderRadius: 5,
        }}
      >
        <Typography variant="h4" gutterBottom>
          General Information
        </Typography>

        <Grid container spacing={2}>
          {/* Patient ID */}
          <Grid size={3}>
            <TextField
              label="Patient ID (No PII)"
              name="patientId"
              value={data.patientId}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Birth Year */}
          <Grid size={3}>
            <TextField
              label="Birth Year (YYYY)"
              name="dob"
              type="number"
              value={data.dob}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Ethnicity */}
          <Grid size={3}>
            <FormControl fullWidth>
              <TextField
                select
                label="Ethnicity"
                name="ethnicity"
                value={data.ethnicity}
                onChange={handleChange}
                fullWidth
              >
                {ethnicityOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          {/* Sex */}
          <Grid size={3}>
            <FormControl fullWidth>
              <TextField
                select
                label="Sex"
                name="sex"
                value={data.sex}
                onChange={handleChange}
                fullWidth
              >
                {sexOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          {/* Past Ocular History */}
          <Grid size={8}>
            <TextField
              label="Past Ocular History and Other Notes"
              name="pastOcularHistory"
              value={data.pastOcularHistory}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="body1" gutterBottom>
          Data is encrypted and stored securely but please do not include any personally identifiable information (PII) in any field. 
          This includes patient names, addresses, phone numbers, insurance information, or any other information that could be used 
          to identify a patient. Only include diagnostic information if it is relevant to the patient's eye health or glaucoma.
        </Typography>

        {/* OD and OS Sections */}
        <Box mt={2}>
          <Grid container spacing={3}>
            {/* OD Column */}
            <Grid size={6}>
              <Box
                sx={{
                  backgroundColor: '#e3f2fd', // Light blue
                  py: 2,
                  px: 6,
                  borderRadius: 1,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  OD
                </Typography>
                {[
                  {
                    label: 'Pachymetry (required)',
                    name: 'pachymetryOD',
                    type: 'number',
                    value: displayValue(data.pachymetryOD),
                  },
                  {
                    label: 'Autorefraction',
                    name: 'autorefractionOD',
                    type: 'text',
                    value: data.autorefractionOD,
                  },
                  {
                    label: 'Corneal Astigmatism (required)',
                    name: 'cornealAstigmatismOD',
                    type: 'number',
                    value: displayValue(data.cornealAstigmatismOD),
                  },
                ].map((field, index) => (
                  <Box key={index} mb={2}>
                    <TextField
                      label={field.label}
                      name={field.name}
                      type={field.type}
                      value={field.value}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Box>
                ))}
              </Box>
            </Grid>

            {/* OS Column */}
            <Grid size={6}>
              <Box
                sx={{
                  backgroundColor: '#fce4ec', // Light pink
                  py: 2,
                  px: 6,
                  borderRadius: 1,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  OS
                </Typography>
                {/* OS Fields */}
                {[
                  {
                    label: 'Pachymetry (required)',
                    name: 'pachymetryOS',
                    type: 'number',
                    value: displayValue(data.pachymetryOS),
                  },
                  {
                    label: 'Autorefraction',
                    name: 'autorefractionOS',
                    type: 'text',
                    value: data.autorefractionOS,
                  },
                  {
                    label: 'Corneal Astigmatism (required)',
                    name: 'cornealAstigmatismOS',
                    type: 'number',
                    value: displayValue(data.cornealAstigmatismOS),
                  },
                ].map((field, index) => (
                  <Box key={index} mb={2}>
                    <TextField
                      label={field.label}
                      name={field.name}
                      type={field.type}
                      value={field.value}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default PatientInfo;
